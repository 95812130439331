
import React from 'react';
import { Box, Heading, Text, Image, List, ListItem, Table, Thead, Tbody, Tr, Th, Td, Container, Divider, useBreakpointValue, ListIcon } from '@chakra-ui/react';

const Blocks = () => {
    const headingSize = useBreakpointValue({ base: 'xl', md: '2xl' });
    const subHeadingSize = useBreakpointValue({ base: 'lg', md: 'xl' });
    const paragraphSize = useBreakpointValue({ base: 'md', md: 'lg' });

    return (
        <Container maxW="container.xl" py={8}>
            <Box p={6} borderRadius="md" boxShadow="md" bg="white">
                <Heading as="h1" fontSize={headingSize} mb={6} color="teal.600">
                    Blocks & Bricks
                </Heading>

                <Box mb={8}>
                    <Image
                        src="./hallow.jpg"
                        alt="Hallow Blocks"
                        borderRadius="md"
                        mb={4}
                        boxShadow="sm"
                        transition="transform 0.3s ease, box-shadow 0.3s ease"
                        _hover={{
                            cursor: 'pointer',
                            transform: 'scale(1.05)',
                            boxShadow: 'md',
                        }}
                    />
                    <Heading as="h2" fontSize={subHeadingSize} mb={4} color="teal.600">
                        Hallow Blocks
                    </Heading>
                    <Text fontSize={paragraphSize} mb={4}>
                        120 pc pallet for 100 & 75 mm thickness packed in wooden pallet as per standard export packing
                    </Text>
                    <Text fontSize={paragraphSize} mb={4}>
                        We offer hollow blocks. Packing:
                    </Text>
                    <List spacing={3} mb={6}>
                        <ListItem>60 pc pallet for 200 mm thickness</ListItem>
                        <ListItem>80 pc pallet for 150 mm thickness</ListItem>
                        <ListItem>120 pc pallet for 100 & 75 mm thickness</ListItem>
                    </List>
                    <Text fontSize={paragraphSize}>
                        Packed in wooden pallet as per standard export packing.
                    </Text>
                </Box>

                <Box mb={8}>
                    <Image
                        src="./blocksandbricks.jpg"
                        alt="Blocks and Bricks"
                        borderRadius="md"
                        mb={4}
                        boxShadow="sm"
                        transition="transform 0.3s ease, box-shadow 0.3s ease"
                        _hover={{
                            cursor: 'pointer',
                            transform: 'scale(1.05)',
                            boxShadow: 'md',
                        }}
                    />
                    <Heading as="h2" fontSize={subHeadingSize} mb={4} color="teal.600">
                        Bricks
                    </Heading>
                    <Text fontSize={paragraphSize} mb={4}>
                        Size (Inches): 9in*3in*20mm For Use in: Side Walls
                    </Text>
                    <Text fontSize={paragraphSize} mb={4}>
                        We deal in designer bricks for wall cladding. Can be used both external and internal cladding. Its excellent strength and durability, we have fascinating colors, its lowest water absorption and fire resistance. Size available in 225mm*42mm*20mm.
                    </Text>
                </Box>

                <Box mb={8}>
                    <Image
                        src="./fly_ash.jpg"
                        alt="Fly Ash Bricks"
                        borderRadius="md"
                        mb={4}
                        boxShadow="sm"
                        transition="transform 0.3s ease, box-shadow 0.3s ease"
                        _hover={{
                            cursor: 'pointer',
                            transform: 'scale(1.05)',
                            boxShadow: 'md',
                        }}
                    />
                    <Heading as="h2" fontSize={subHeadingSize} mb={4} color="teal.600">
                        Fly Ash Bricks
                    </Heading>
                    <Text fontSize={paragraphSize} mb={4}>
                        Properties Of Fly Ash Bricks..
                    </Text>
                    <Table variant="simple" mb={6} size="lg">
                        <Thead>
                            <Tr>
                                <Th colSpan={2} textAlign="left" fontSize="lg">Product Details</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>
                                    <Text fontSize="md">For Use in:</Text>
                                </Td>
                                <Td>
                                    <Text fontSize="md">Side Walls, Partition Walls</Text>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Text fontSize="md">Size (Inches):</Text>
                                </Td>
                                <Td>
                                    <Text fontSize="md">9 In. X 4 In. X 3 In., 9 In. X 3 In. X 2 In.</Text>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Text fontSize="md">Resistance Durability:</Text>
                                </Td>
                                <Td>
                                    <Text fontSize="md">Fire Resistant, Heat Resistant</Text>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    <Text fontSize="md">Brand:</Text>
                                </Td>
                                <Td>
                                    <Text fontSize="md">ACC, ACB</Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>

                <Box mb={8}>
                    <Image
                        src="./fire.jpg"
                        alt="Refractory Fire Bricks"
                        borderRadius="md"
                        mb={4}
                        boxShadow="sm"
                        transition="transform 0.3s ease, box-shadow 0.3s ease"
                        _hover={{
                            cursor: 'pointer',
                            transform: 'scale(1.05)',
                            boxShadow: 'md',
                        }}
                    />
                    <Heading as="h2" fontSize={subHeadingSize} mb={4} color="teal.600">
                        Refractory Fire Bricks
                    </Heading>
                    <Text fontSize={paragraphSize} mb={4}>
                        Our fire bricks, slabs & blocks are manufactured strictly by specification. Our group has supplied high-quality fire bricks since the past five decades.
                    </Text>
                    <Text fontSize={paragraphSize} mb={4}>
                        Our fire bricks are of superior quality providing maximum performance with zero defects. With our years of refractory knowledge, our bricks are preferred by Steel, Power, Fertilizer, Sugar & Power Plants across India. We can provide bricks from IS-6, IS-8 quality up to 90% Alumina bricks, our range includes low ferric, high alumina, sillimanite, magnesite bricks. We can manufacture these bricks in a number of shapes & sizes.
                    </Text>
                    <Text fontSize={paragraphSize} mb={4}>
                        Our 70% Alumina Brick known in India as ‘DuraBrick’ is a great success, it is our highest selling bricks (by MT). Major industries have specified our bricks for their furnaces, ladles & boilers. We are able to achieve such quality as we keep a strict watch on our manufacturers production process and get these manufactured strictly by specification. Then complete sorting & random lab testing is done for every lot. We have been following these procedures for the last four decades to ensure the best quality for our customers.
                    </Text>
                </Box>

                <Box mb={8}>
                    <Image
                        src="./designer.jpg"
                        alt="Designer Bricks Cladding"
                        borderRadius="md"
                        mb={4}
                        boxShadow="sm"
                        transition="transform 0.3s ease, box-shadow 0.3s ease"
                        _hover={{
                            cursor: 'pointer',
                            transform: 'scale(1.05)',
                            boxShadow: 'md',
                        }}
                    />
                    <Heading as="h2" fontSize={subHeadingSize} mb={4} color="teal.600">
                        Designer Bricks Cladding
                    </Heading>
                    <Text fontSize={paragraphSize} mb={4}>
                        We deal in designer bricks for wall cladding. Can be used both external and internal cladding. Its excellent strength and durability, we have fascinating colors, its lowest water absorption and fire resistance. Size available in 225mm*42mm*20mm.
                    </Text>
                    <Table variant="simple" mb={6}>
                        <Thead>
                            <Tr>
                                <Th>Product Details:</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>
                                    <Text fontSize={paragraphSize}>
                                        Minimum Order Quantity: 500 Piece
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={paragraphSize}>
                                        Size (Inches): 9in*3in*20mm
                                    </Text>
                                </Td>
                                <Td>
                                    <Text fontSize={paragraphSize}>
                                        For Use in: Side Walls
                                    </Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>

                <Box mb={8}>
                    <Image
                        src="./acid_resistant.jpg"
                        alt="Acid Resistant Brick"
                        borderRadius="md"
                        mb={4}
                        boxShadow="sm"
                        transition="transform 0.3s ease, box-shadow 0.3s ease"
                        _hover={{
                            cursor: 'pointer',
                            transform: 'scale(1.05)',
                            boxShadow: 'md',
                        }}
                    />
                    <Heading as="h2" fontSize={subHeadingSize} mb={4} color="teal.600">
                        Acid Resistant Brick
                    </Heading>
                    <Text fontSize={paragraphSize} mb={4}>
                        They are used as flooring lining in many kinds of chemical reactors, towers (including drying towers, absorption towers, etc.), and tanks of acids & other chemicals in chemical factories, fertilizer factories, sulphuric acid factories, iron & steel factories, etc. They are used as lining of antiseptic pools and ditches.
                    </Text>
                    <Heading as="h2" fontSize={subHeadingSize} mb={4} color="teal.600">
                        Installation:
                    </Heading>
                    <List spacing={4} mt={4}>
                        <ListItem>
                            <ListIcon color="green.500" />
                            <Text>
                                Using oxalic acid to wash the surface of concrete and tiles, neutralizing acid and alkali.
                                Usually, we use colophony, araldite, and acid-proof cement to install the tiles.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon color="green.500" />
                            <Text>
                                The slot between the tiles is usually 8-10mm. It's better to make the slot thickness the same as the tile thickness
                                to prevent chemical leakage. After installation, use oxalic acid to wash the surface of the floor.
                            </Text>
                        </ListItem>
                    </List>
                </Box>
                <Box>
                    <Image
                        src="./pavor_blocks.jpg"
                        alt="pavor_blocks"
                        borderRadius="md"
                        mb={4}
                        boxShadow="sm"
                        transition="transform 0.3s ease, box-shadow 0.3s ease"
                        _hover={{
                            cursor: 'pointer',
                            transform: 'scale(1.05)',
                            boxShadow: 'md',
                        }}
                    />

                    <Heading as="h2" fontSize={subHeadingSize} mb={4} color="teal.600">
                        Paving Blocks
                    </Heading>

                    <Text fontSize={paragraphSize} mb={4}>
                        We have vast knowledge and years of experience, and we are involved in providing a premium quality range of Paving Blocks.
                    </Text>

                    <Heading as="h3" fontSize="lg" mb={2}>
                        Features:
                    </Heading>
                    <List spacing={2} mb={4}>
                        <ListItem>Material: Concrete</ListItem>
                        <ListItem>Color: Grey</ListItem>
                        <ListItem>Uses: Outdoor</ListItem>
                    </List>

                    <Divider mb={4} />

                    <Heading as="h3" fontSize="lg" mb={2}>
                        Product Details:
                    </Heading>
                    <List spacing={2} mb={4}>
                        <ListItem>Color: Grey</ListItem>
                        <ListItem>Shape: Rectangular, Square</ListItem>
                    </List>

                </Box>

                <Image src="./designer_pavor.jpg" alt="designer_pavor" mb={6} borderRadius="md" boxShadow="md" />

                <Heading as="h2" size="xl" mb={4}>Designer Paving Blocks</Heading>
                <Text fontSize={paragraphSize} mb={4}>
                    We are offering Designer Paving Blocks specially designed to be used in various outdoor applications.
                </Text>
                <Table variant="striped" colorScheme="teal" mb={6} size="md">
                    <Thead>
                        <Tr>
                            <Th fontSize="lg" color="teal.600">Product Details</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                                <Text fontSize="md" fontWeight="bold">Minimum Order Quantity:</Text>
                                <Text fontSize="sm" color="gray.600">5000 Square Feet</Text>
                            </Td>
                            <Td>
                                <Text fontSize="md" fontWeight="bold">Color:</Text>
                                <Text fontSize="sm" color="gray.600">Red, Grey, Black, Orange, Coffee</Text>
                            </Td>
                            <Td>
                                <Text fontSize="md" fontWeight="bold">Thickness:</Text>
                                <Text fontSize="sm" color="gray.600">60mm</Text>
                            </Td>
                            <Td>
                                <Text fontSize="md" fontWeight="bold">Material:</Text>
                                <Text fontSize="sm" color="gray.600">Concrete</Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>


                <Heading as="h3" fontSize="lg" mb={2}>Specifications:</Heading>
                <List spacing={2} mb={6}>
                    <ListItem>Minimum Order Quantity: 5000 Square Feet</ListItem>
                    <ListItem>Color: Red, Grey, Black, Orange, Coffee</ListItem>
                    <ListItem>Thickness: 60mm</ListItem>
                    <ListItem>Material: Concrete</ListItem>
                </List>

                <Image src="./flixi_pavor.jpg" alt="Flexi Paver Block" mb={6} borderRadius="md" boxShadow="md" />

                <Heading as="h2" size="lg" mb={4}>Flexi Paver Block</Heading>
                <Text fontSize={paragraphSize} mb={4}>
                    With enriched industrial practice and acquaintance, we are offering our patrons with an extensive range of Flexi Paver Block.
                </Text>

                <Heading as="h3" fontSize="lg" mb={2}>Features:</Heading>
                <List spacing={2} mb={4}>
                    <ListItem>Rugged design</ListItem>
                    <ListItem>Low maintenance</ListItem>
                    <ListItem>Nominal prices</ListItem>
                </List>

                <Heading as="h3" fontSize="lg" mb={2}>Specifications:</Heading>
                <List spacing={2} mb={4}>
                    <ListItem>Height: 237 mm</ListItem>
                    <ListItem>Length: 237 mm</ListItem>
                    <ListItem>Area: 0.173 Sqft</ListItem>
                </List>

                <Text fontSize={paragraphSize} mb={4}>
                    Minimum Order Quantity: 500 Square Feet
                </Text>

                <Image src="./colordo.jpg" alt="Colorado Paver Block" mb={6} borderRadius="md" boxShadow="md" />

                <Heading as="h2" size="lg" mb={4}>Colorado Paver Block</Heading>
                <Text fontSize={paragraphSize} mb={4}>
                    So as to meet the increasing demands of our clients, we are offering Colorado Paver Block for pavement construction.
                </Text>

                <Heading as="h3" fontSize="lg" mb={2}>Specifications:</Heading>
                <List spacing={2} mb={4}>
                    <ListItem>Area: 0.44 sqft</ListItem>
                    <ListItem>Thickness: 70 to 80 mm</ListItem>
                    <ListItem>Length: 200 mm</ListItem>
                </List>

                <Text fontSize={paragraphSize} mb={4}>
                    Minimum Order Quantity: 500 Square Feet
                </Text>

                <Image src="./interlink.jpg" alt="Interlocking Paver Blocks" mb={6} borderRadius="md" boxShadow="md" />

                <Heading as="h2" size="lg" mb={4}>Interlocking Paver Blocks</Heading>
                <Text fontSize={paragraphSize} mb={4}>
                    Owing to our expertise, we are providing our clients with a high-quality series of Interlocking Paver Blocks in various colors and patterns.
                </Text>

                <Heading as="h3" fontSize="lg" mb={2}>Specifications:</Heading>
                <List spacing={2} mb={4}>
                    <ListItem>Area: 0.44 Sqft</ListItem>
                    <ListItem>Thickness: 60 to 80 mm</ListItem>
                    <ListItem>Length: 250 mm</ListItem>
                </List>

                <Text fontSize={paragraphSize} mb={4}>
                    Minimum Order Quantity: 500 Square Feet
                </Text>

                <Image src="./combi.jpg" alt="Combi Pavers Block" mb={6} borderRadius="md" boxShadow="md" />

                <Heading as="h2" size="lg" mb={4}>Combi Pavers Block</Heading>
                <Text fontSize={paragraphSize} mb={4}>
                    Our customers can avail from us a wide series of Combo Pavers Block, appreciated for its top features.
                </Text>

                <Heading as="h3" fontSize="lg" mb={2}>Features:</Heading>
                <List spacing={2} mb={4}>
                    <ListItem>Reasonable pricing</ListItem>
                    <ListItem>Sturdy nature</ListItem>
                    <ListItem>Impeccable finish</ListItem>
                </List>

                <Heading as="h3" fontSize="lg" mb={2}>Specifications:</Heading>
                <List spacing={2} mb={4}>
                    <ListItem>Height: 277 mm</ListItem>
                    <ListItem>Length: 277 mm</ListItem>
                    <ListItem>Area: 0.43 Sqft</ListItem>
                </List>

                <Text fontSize={paragraphSize} mb={4}>
                    Minimum Order Quantity: 500 Square Feet
                </Text>

                <Box textAlign="center" mt={8} borderTop="2px" borderColor="gray.200" pt={4}>
                    <Text fontSize="lg" fontWeight="bold" color="teal.600">
                        www.ametheus.com
                    </Text>
                    <Text fontSize="md" color="gray.500">
                        info@ametheus.com
                    </Text>
                </Box>
            </Box>
        </Container >
    );
};

export default Blocks;
