import { Box, Heading, Text, Image, VStack, Divider } from '@chakra-ui/react';
import React from 'react';

const Cement = () => {
    return (
        <VStack spacing={8} align="start" p={6} maxW="1200px" mx="auto">
            {/* Section 1 - General Cement Overview */}
            <Box>
                <Heading as="h1" size="xl" mb={4} color="teal.700">
                    Cement
                </Heading>
                <Text fontSize="lg" color="gray.600">
                    OPC 53 grade cement is used for cost-effective, speedy constructions where initial higher strength is the criteria. It provides long-lasting durability to concrete structures. This cement is used in high-rise residential & commercial buildings and also for pre-stressed concrete of higher grades. OPC 43 Grade Cement takes less setting time and provides long life to concrete structures such as residential & commercial buildings, RCC water tanks, etc. PPC cement is best suited for non-building concreting works like brick/block walls, flooring, and plastering as it reduces the chances of developing cracks due to low heat of hydration.
                </Text>
                <Text fontSize="lg" mt={4} color="gray.600">
                    <b>Types:</b> Cement OPC 43 | Cement OPC 53 | Cement PPC | Premium Cements
                    <br />
                    <b>Brands:</b> ACC | Ultratech | Ambuja | Dalmia | Vasavdatta
                </Text>
            </Box>

            <Divider borderColor="gray.300" />

            {/* Section 2 - Cement Details with Image */}
            <Box>
                <Image
                    src="./cement.jpg"
                    alt="Cement"
                    borderRadius="lg"
                    boxShadow="lg"
                    mb={4}
                    maxH="400px"
                    objectFit="cover"
                    mx="auto"
                />
                <Heading as="h2" size="lg" mb={4} color="teal.700">
                    Cement 1000 kgs
                </Heading>
                <Text fontSize="lg" color="gray.600">
                    1 metric ton = 1 ton jumbo bag packed in 45-48 kgs PP bag, HDPE bag, and loose packing options. We can also offer cement packed in 50 kg paper bags. 1 metric ton = 1000 kgs, which can be packed in (21 to 22 kg bags).
                </Text>
            </Box>

            <Divider borderColor="gray.300" />

            {/* Section 3 - 53 GRADE Cement */}
            <Box>
                <Heading as="h2" size="lg" mb={4} color="teal.700">
                    53 GRADE | SUPEROOF
                </Heading>
                <Text fontSize="lg" color="gray.600">
                    The roof is an essential aspect of a house or building. It provides shelter and protection while enduring environmental forces and air pollutants. SUPEROOF cement is designed to provide extra protection and strength compared to ordinary cements. It ensures denser concrete, guarding against cracks and water leaks for a long-lasting roof.
                </Text>
            </Box>

            <Divider borderColor="gray.300" />

            {/* Section 4 - 43 GRADE Cement */}
            <Box>
                <Heading as="h2" size="lg" mb={4} color="teal.700">
                    43 GRADE
                </Heading>
                <Text fontSize="lg" color="gray.600">
                    The 43 Grade OPC cement possesses superior quality. Our product test certificates show that its strength is at least 10 MPa higher than BIS standards for various ages (3, 7, 28 days). This offers high strength to concrete structures, ensuring durability and reducing the amount of cement required for higher-grade concrete.
                </Text>
                <Text fontSize="lg" color="gray.600">
                    <b>Gigantic compressive strength for superior grade of concrete and for economizing the project: </b> <br />

                    Presence of consistent quality of cement grade Lime stone, which makes it possible to go for the most stringent quality standards and hence the compressive strength obtained for 43Gr.OPC is superior and at least 10 Mpa higher than the specified requirements of BIS standards for all ages viz 3 days, 7days and 28days. This offers mighty strength to the concrete structures with extreme degree of durability and enables higher grade of concrete with cement content lower than that specified for the grade. There is also a possibility of reducing the cement content for a given grade of concrete if the same can be replaced with fine mineral admixtures to economize your project.
                </Text>
            </Box>

            <Divider borderColor="gray.300" />

            {/* Section 5 - Airstrip Cement */}
            <Box>
                <Image
                    src="./as.jpg"
                    alt="Airstrip Cement"
                    borderRadius="lg"
                    boxShadow="lg"
                    mb={4}
                    maxH="400px"
                    objectFit="cover"
                    mx="auto"
                />
                <Heading as="h2" size="lg" mb={4} color="teal.700">
                    Airstrip Cement
                </Heading>
                <Text fontSize="lg" color="gray.600">
                    Infrastructure services depend on a reliable supply of quality cement. Airstrip cement, known for its high impact load-bearing capacity, is ideal for manufacturing airstrips and roads. This cement was used to construct defense airstrips in Punjab by the Indian government.
                </Text>
            </Box>
        </VStack>
    );
};

export default Cement;
