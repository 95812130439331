import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    VStack,
    HStack,
    Tag,
    TagLabel,
    TagCloseButton,
} from '@chakra-ui/react';
import axios from 'axios';

const UserProfileForm = () => {
    const id = localStorage.getItem('usrId') || undefined;
    const authorization = localStorage.getItem('AstToken') || undefined;
    const head = {
        id,
        authorization,
        'Content-Type': 'application/json'  // Use application/json
    };

    const [data, setData] = useState({
        shortDescription: '',
        address: { city: '', state: '', houseNo: '', street: '', country: '' },
        companyName: '',
        website: '',
        localities: [],
        languages: [],
        userLanguage: '', // Ensure userLanguage is part of the initial state
        userLocality: ''  // Add userLocality to initial state
    });

    const [languagesList, setLanguagesList] = useState([]);
    const [localitiesList, setLocalitiesList] = useState([]);

    useEffect(() => {
        // Fetch data on component mount
        axios
            .get(`${process.env.REACT_APP_URL}/user`, { headers: head })
            .then(response => {
                const fetchedData = response.data;
                setData({
                    ...fetchedData,
                    userLanguage: '', // Initialize userLanguage to an empty string
                    userLocality: ''  // Initialize userLocality to an empty string
                });
                setLanguagesList(fetchedData.languages || []);
                setLocalitiesList(fetchedData.localities || []);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            address: {
                ...prevData.address,
                [name]: value
            }
        }));
    };

    const handleLanguageChange = (e) => {
        const { value } = e.target;
        setData(prevData => ({ ...prevData, userLanguage: value }));
    };

    const handleLocalityChange = (e) => {
        const { value } = e.target;
        setData(prevData => ({ ...prevData, userLocality: value }));
    };

    const addLanguage = () => {
        if (data.userLanguage && !languagesList.includes(data.userLanguage)) {
            setLanguagesList([...languagesList, data.userLanguage]);
            setData(prevData => ({ ...prevData, userLanguage: '' }));
        }
    };

    const addLocality = () => {
        if (data.userLocality && !localitiesList.includes(data.userLocality)) {
            setLocalitiesList([...localitiesList, data.userLocality]);
            setData(prevData => ({ ...prevData, userLocality: '' }));
        }
    };

    const removeLanguage = (language) => {
        setLanguagesList(languagesList.filter(lang => lang !== language));
    };

    const removeLocality = (locality) => {
        setLocalitiesList(localitiesList.filter(loc => loc !== locality));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedData = {
            ...data,
            languages: languagesList,
            localities: localitiesList
        };
        delete updatedData.userLanguage;
        delete updatedData.userLocality;

        axios
            .patch(`${process.env.REACT_APP_URL}/user/update`, updatedData, { headers: head })
            .then(response => console.log('Data updated successfully:', response.data))
            .catch(error => console.error('Error updating data:', error));
        console.log(updatedData); // Log updated data to verify
    };

    return (
        <Box p={4} width="50vw" borderWidth={1} borderRadius="lg" overflow="hidden">
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <FormControl id="shortDescription">
                        <FormLabel>Short Description</FormLabel>
                        <Textarea
                            name="shortDescription"
                            value={data.shortDescription}
                            onChange={handleInputChange}
                        />
                    </FormControl>

                    <FormControl id="address">
                        <FormLabel>Address</FormLabel>
                        <VStack spacing={2}>
                            <Input
                                name="houseNo"
                                placeholder="House No"
                                value={data.address?.houseNo}
                                onChange={handleAddressChange}
                            />
                            <Input
                                name="street"
                                placeholder="Street"
                                value={data.address?.street}
                                onChange={handleAddressChange}
                            />
                            <Input
                                name="city"
                                placeholder="City"
                                value={data.address?.city}
                                onChange={handleAddressChange}
                            />
                            <Input
                                name="state"
                                placeholder="State"
                                value={data.address?.state}
                                onChange={handleAddressChange}
                            />

                            <Input
                                name="country"
                                placeholder="Country"
                                value={data.address?.country}
                                onChange={handleAddressChange}
                            />
                        </VStack>
                    </FormControl>

                    <FormControl id="companyName">
                        <FormLabel>Company Name</FormLabel>
                        <Input
                            name="companyName"
                            value={data.companyName}
                            onChange={handleInputChange}
                        />
                    </FormControl>

                    <FormControl id="website">
                        <FormLabel>Company Website</FormLabel>
                        <Input
                            name="website"
                            value={data.website}
                            onChange={handleInputChange}
                        />
                    </FormControl>

                    <FormControl id="userLocalities">
                        <FormLabel>User Localities</FormLabel>
                        <HStack>
                            <Input
                                name="userLocality"
                                placeholder="Type a locality"
                                value={data.userLocality}
                                onChange={handleLocalityChange}
                            />
                            <Button onClick={addLocality}>Add</Button>
                        </HStack>
                        <HStack spacing={2} mt={2}>
                            {localitiesList.map((locality, index) => (
                                <Tag key={index} size="lg" colorScheme="blue">
                                    <TagLabel>{locality}</TagLabel>
                                    <TagCloseButton onClick={() => removeLocality(locality)} />
                                </Tag>
                            ))}
                        </HStack>
                    </FormControl>

                    <FormControl id="userLanguage">
                        <FormLabel>User Language</FormLabel>
                        <HStack>
                            <Input
                                name="userLanguage"
                                placeholder="Type a language"
                                value={data.userLanguage}
                                onChange={handleLanguageChange}
                            />
                            <Button onClick={addLanguage}>Add</Button>
                        </HStack>
                        <HStack spacing={2} mt={2}>
                            {languagesList.map((language, index) => (
                                <Tag key={index} size="lg" colorScheme="blue">
                                    <TagLabel>{language}</TagLabel>
                                    <TagCloseButton onClick={() => removeLanguage(language)} />
                                </Tag>
                            ))}
                        </HStack>
                    </FormControl>

                    <Button type="submit" colorScheme="teal" size="md">
                        Submit
                    </Button>
                </VStack>
            </form>
        </Box>
    );
};

export default UserProfileForm;
