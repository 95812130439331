// src/ShareButton.js
import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share';

const ShareButton = ({ url, title, description }) => {
  return (
    <div className="share-buttons">
      <EmailShareButton url={url} subject={title} body={description}>
        <EmailIcon size={32} round />
      </EmailShareButton>
      <WhatsappShareButton url={url} title={title}  separator=":: ">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <FacebookShareButton url={url} quote={title} hashtag="#yourCompany">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} via="yourCompany" hashtags={["yourCompany"]}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </div>
  );
};

export default ShareButton;
