import React from 'react';
import { Box, Image, List, ListItem, Tab, TabList, TabPanel, TabPanels, Tabs, Text , Heading } from '@chakra-ui/react';

const Plywood = () => {
  return (
    <Box p={5} bg="#f4f4f4" fontFamily="Arial, sans-serif">
      <Text fontSize="2xl" mb={5}>Plywood</Text>

      <Tabs isLazy>
        <TabList mb={5} display="flex" gap={5} flexWrap="wrap">
          <Tab>Plywood</Tab>
          <Tab>Hardwood</Tab>
          <Tab>MDF Board</Tab>
          <Tab>Green Shutter Plywood</Tab>
          <Tab>Pre Laminated Plywood</Tab>
          <Tab>Laminated Plywood</Tab>
          <Tab>Timber</Tab>
          <Tab>Red Meranti</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box borderWidth={2} p={5} mb={5} bg="white">
              <Image src="plyhardwood.jpg" boxSize="700px" mb={4} />
              <Text fontSize="lg" fontWeight="bold">Plywood</Text>
              <Text mb={2}>
                We deal in a wide variety of Plywood Textures which is widely
                demanded on a large scale. The demand is witnessed by builders,
                civil contractors and interior designers. The plywood is available
                in various sizes.
              </Text>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box borderWidth={2} p={5} mb={5} bg="white">
              <Image src="hardwood.jpg" boxSize="700px" mb={4} />
              <Text fontSize="lg" fontWeight="bold">Hardwood</Text>
              <Text mb={2}>
                Machining - Average of 5 operations: Planing, shaping, turning,
                boring and mortising (High of 10). Resistance To Splitting -
                Resistance to splitting from nail or screw (High of 10).
              </Text>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box borderWidth={2} p={5} mb={5} bg="white">
              <Image src="mdf.jpg" boxSize="700px" mb={4} />
              <Text fontSize="lg" fontWeight="bold">MDF Board</Text>
              <Text mb={2}>
                With the support of our experts and sophisticated in-house
                facilities, we have been able to offer an array of Green MDF
                Pandmax Board that is known for their high quality reusable value
                and salvage value.
              </Text>
              <Text mb={2}>
                The offered pandmax board is designed by our trained professionals
                utilizing the finest quality hardwood veneers and BWP grade of
                phenol formaldehyde. This pandmax board is made available in an
                assortment of customized options as per the needs of customers.
              </Text>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box borderWidth={2} p={5} mb={5} bg="white">
              <Image src="gren_ply.jpg" boxSize="700px" mb={4} />
              <Text fontSize="lg" fontWeight="bold">Green Shutter Plywood</Text>
              <Text mb={2}>
                We are recognized as the prominent manufacturer, exporter and
                supplier of a wide collection of Green Shutter Plywood. This
                shutter plywood is designed using optimum quality basic material
                in absolute conformity with set norms of market.
              </Text>
              <Text mb={2}>
                The provided shutter plywood has super tuff bonding and has been
                tested for number of hours in boiling water. In addition, our
                respected clients can avail this shutter plywood from us at
                affordable prices.
              </Text>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box borderWidth={2} p={5} mb={5} bg="white">
              <Image src="pre_laminated_ply.jpg" boxSize="700px" mb={4} />
              <Text fontSize="lg" fontWeight="bold">Pre Laminated Plywood</Text>
              <Text mb={2}>
                Colours in Both side laminated (BSL) - half-white, sapeli, teak,
                walnut Colours in one-side laminated (OSL) - half-white.
              </Text>
              <Text mb={2}>Minimum Order Quantity: 320 Square Feet</Text>
              <Text fontWeight="bold">Specifications:</Text>
              <List spacing={2}>
                <ListItem>Thickness: 6-18mm</ListItem>
                <ListItem>Material: Wood</ListItem>
                <ListItem>Color: Brown with White</ListItem>
              </List>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box borderWidth={2} p={5} mb={5} bg="white">
              <Image src="laminated_ply.jpg" boxSize="700px" mb={4} />
              <Text fontSize="lg" fontWeight="bold">Laminated Plywood</Text>
              <Text mb={2}>
                Keeping in mind ever-evolving requirements of our respected
                clients, we are offering a premium quality range of Laminated
                Plywood.
              </Text>
              <Text mb={2}>Brand: Shree Bhagwati Saw Mill</Text>
              <Text mb={2}>Thickness: 4mm-6mm</Text>
              <Text mb={2}>Features: Durability, Fine Quality</Text>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box borderWidth={2} p={5} mb={5} bg="white">
              <Image src="timber5_4.jpg" boxSize="700px" mb={4} />
              <Text fontSize="lg" fontWeight="bold">Timber</Text>
              <Text mb={2}>
                From interior finishes through to structural damage, this
                wide-ranging collection of resources includes structural grades,
                span tables and durability tests to assist with building and
                design compliance. Software & design resources from industry
                bodies and major suppliers provide additional tools for modern
                building design.
              </Text>
              <Text mb={2}>
                Specification is the process of communicating the designer's
                intentions to the supplier of the material. There must be a clear
                and unambiguous specification for all structural timber (including
                framing) required in their design. Specifiers must be able to
                communicate...
              </Text>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box borderWidth={2} p={5} mb={5} bg="white">
              <Image src="timberredmeranti.jpg" boxSize="700px" mb={4} />
              <Text fontSize="lg" fontWeight="bold">Red Meranti</Text>
              <Text mb={2}>
                Typically a dark reddish or purplish brown; commonly with white
                resin streaks present.
              </Text>
              <Text mb={2}>Common Name(s): Dark Red Meranti, Lauan, Philippine Mahogany</Text>
              <Text mb={2}>Scientific Name: Shorea spp.</Text>
              <Text mb={2}>Distribution: Southeast Asia</Text>
              <Text mb={2}>
                Tree Size: 65-130 ft (20-40 m) tall, 3-6 ft (1-2 m) trunk diameter
              </Text>
              <Text mb={2}>Average Dried Weight: 42 lbs/ft3 (675 kg/m3)</Text>
              <Text mb={2}>Specific Gravity (Basic, 12% MC): .55, .68</Text>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box>
                <Heading style={{fontSize:"22px"}}> <b> Iron, Steel & Copper Items:</b><br /> Deform Steel Bars, GI Pipes, Hollow Sections, MS Angles, Channels, MS Flat Bars,
                    Flat Bars, Fasteners, Stainless Steel, Copper Items</Heading>
                    <h1 style={{fontSize:"18px" , fontWeight:"700"}}>STEEL  SEMIS</h1>
                    <img style={{margin:"20px 0"}} src="./billets.webp" alt="" />
                    <h1 style={{fontSize:"22px" , fontWeight:"700"}}>STEEL  LONG PRODUCTS</h1>
                    <img style={{margin:"20px 0"}} src="./steellong-products.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./beams.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./wire-rods.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./steel-pipes.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./angles.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./channels.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./hollow-sections.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./hot-rolled-coils.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./hot-rolled-sheets.webp" alt="steellong-products" />
            </Box>
    </Box>
  );
};

export default Plywood;
