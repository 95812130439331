import React, { useState, useEffect } from 'react';
import { Button, Input, Box, VStack, Select } from '@chakra-ui/react';
import axios from 'axios';
import './Agent.css';
import { CiSearch } from "react-icons/ci";
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Agent = () => {
    const [agents, setAgents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredAgents, setFilteredAgents] = useState([]);
    const [selectedLocality, setSelectedLocality] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');

    useEffect(() => {
        axios.get('https://api.assetorix.com/assetorix/user/agents')
            .then(response => {
                console.log(response.data.data);
                setAgents(response.data.data);
                setFilteredAgents(response.data.data);
            })
            .catch(error => console.error('Error fetching agents:', error));
    }, []);

    useEffect(() => {
        setFilteredAgents(
            agents.filter(agent => {
                const localitiesMatch = agent.localities?.some(locality =>
                    locality.toLowerCase().includes(searchTerm.toLowerCase())
                );
                const localityFilterMatch = selectedLocality ? agent.localities?.includes(selectedLocality) : true;
                const languageFilterMatch = selectedLanguage ? agent.languages?.includes(selectedLanguage) : true;

                return (
                    (agent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        agent.companyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        agent.about?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        agent.address.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        agent.address.state?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        localitiesMatch) &&
                    localityFilterMatch &&
                    languageFilterMatch
                );
            })
        );
    }, [searchTerm, selectedLocality, selectedLanguage, agents]);

    const uniqueLocalities = [...new Set(agents.flatMap(agent => agent.localities || []))];
    const uniqueLanguages = [...new Set(agents.flatMap(agent => agent.languages || []))];

    return (
        <Box>
            <Helmet>
                <title>Find Real Estate Agents | HomeLee</title>
                <meta name="description" content="Find top real estate agents in India and USA for property buying, selling, and renting. HomeLee offers the best property search services for residential and commercial properties." />
                <meta name="keywords" content="Real Estate, Property in India, Property in USA, Property Buy, Property Sell, Property Rent, Real Estate Agents, Property Management, Commercial Real Estate" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, minimum-scale=1.0" />
                <meta name="owner" content="https://www.homelee.com/" />
                <meta name="copyright" content="https://www.homelee.com/" />
                <meta name="classification" content="Real Estate Agent Finder" />
                <meta name="distribution" content="India, USA" />
                <meta name="rating" content="General" />
                <meta name="subject" content="Property Buying, Selling, and Renting; Real Estate Agents in India and USA" />
                <meta name="audience" content="All" />
                <meta name="author" content="HomeLee" />
            </Helmet>
            <Box className='search-box'>
                <Input
                    placeholder="Search agents..."
                    className='agent-searchBar'
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    mb={4}
                />
                <Button
                    colorScheme="blue"
                    className='agent-button'
                    variant="solid"
                    size="sm"
                    onClick={() => setSearchTerm('')}
                >
                    Clear
                </Button>
            </Box>
            <Box className='agent-main-upper' p={4} display="flex" flexDirection="row" gap="20px">
                <VStack spacing={4} flex="1">
                    {filteredAgents.map(agent => (
                        <Box className="main-agent" key={agent._id} borderWidth={1} borderRadius="lg" overflow="hidden">
                            {console.log(agent._id)}
                            <Box className="left-agent">
                                <img src={agent?.avatar || "https://st3.depositphotos.com/15648834/17930/v/450/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"} alt="profile" />
                            </Box>
                            <Box className="right">
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <h3 className="name-agent">{agent.name}</h3>
                                    <NavLink to={`/agent/agentlisting/${agent._id}`}>
                                        <Button className='view-listing'>View Listing</Button>
                                    </NavLink>
                                </Box>


                                <Box>
                                    <p className="para-agent-company">Company Agent at <span>{agent.companyName}</span></p>
                                </Box>
                                <hr />
                                <Box className="about-agent-main" style={{ display: "flex" }}>
                                    <p className="about-agent">About</p>
                                    <p className="para-agent">{agent?.shortDescription || "No description available."}</p>
                                </Box>
                                <hr />
                                <p className="para-agent-address">{agent?.address?.city || "No address available."}, {agent?.address?.state}, {agent?.address?.country}</p>
                                <hr />
                                <Box className="localities">
                                    {agent?.localities && agent?.localities.map((locality, index) => (
                                        <Button width={"min-content"}  fontSize={"14px"} key={index} m={1}>
                                            {locality}
                                        </Button>
                                    ))}
                                </Box>
                                <hr />
                                <Box className="languages">
                                    {agent?.languages && agent?.languages.map((language, index) => (
                                        <Button fontSize={"14px"} key={index} m={1}>
                                            {language}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </VStack>
                <Box className='agent-right' flex="0 0 300px" style={{ position: 'sticky', top: '20px', alignSelf: 'flex-start' }}>
                    <h2>Find Agent</h2>
                    <div className='input-container'>
                        <Input
                            placeholder="Search agents..."
                            className='agent-searchBar'
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            mb={4}
                        />
                        <span className="search-icon"><CiSearch /></span>
                    </div>
                    <Box>
                        <Select
                            placeholder="Select Locality"
                            value={selectedLocality}
                            onChange={e => setSelectedLocality(e.target.value)}
                            mb={4}
                        >
                            {uniqueLocalities.map((locality, index) => (
                                <option key={index} value={locality}>{locality}</option>
                            ))}
                        </Select>
                        <Select
                            placeholder="Select Language"
                            value={selectedLanguage}
                            onChange={e => setSelectedLanguage(e.target.value)}
                            mb={4}
                        >
                            {uniqueLanguages.map((language, index) => (
                                <option key={index} value={language}>{language}</option>
                            ))}
                        </Select>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Agent;
