import React from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  List,
  ListItem,
  useBreakpointValue
} from '@chakra-ui/react';
import image1 from "../../Assests/Aggregate.jpg"
import image2 from "../../Assests/60mm.jpg"
import image3 from "../../Assests/Blue Granite Stone Chips.jpg"
import image4 from "../../Assests/corse_sand.jpg"
import image5 from "../../Assests/landscape aggregate.jpg"

const Aggregates = () => {
  // Responsive font sizes
  const headingSize = useBreakpointValue({ base: '2xl', md: '3xl' });
  const subHeadingSize = useBreakpointValue({ base: 'xl', md: '2xl' });
  const paragraphSize = useBreakpointValue({ base: 'md', md: 'lg' });

  return (
    <Box 
      p={{ base: '15px', md: '20px' }} 
      bg='#f4f7f6' 
      minHeight='100vh'
      color='#333'
    >
      <Box 
        maxW='1200px' 
        mx='auto' 
        p='30px' 
        borderRadius='12px' 
        boxShadow='0 8px 16px rgba(0, 0, 0, 0.1)' 
        bg='#ffffff'
        transition='transform 0.3s ease, box-shadow 0.3s ease'
        _hover={{
          transform: 'scale(1.02)',
          boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Heading 
          as='h1' 
          fontSize={headingSize} 
          mb='15px' 
          color='#34495e'
          textShadow='1px 1px 2px rgba(0, 0, 0, 0.1)'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Aggregates
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px' 
          textAlign='justify'
        >
          Coarse aggregates are an important material used in the production of concrete products, which provides strength to concrete. They are also known as Jelly/stones, Blue Metal or Crushed Stones. Coarse aggregates are particles greater than 4.75mm, but generally range between 9.5mm to 37.5mm in diameter. They can either be from Primary, Secondary or Recycled sources. Gravels constitute the majority of coarse aggregate used in concrete with crushed stone making up most of the remainder.
        </Text>
        <Image 
          src= {image1} 
          alt="Aggregate" 
          borderRadius='12px' 
          mb='20px' 
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          transition='transform 0.3s ease, box-shadow 0.3s ease'
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mt='25px' 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Aggregate 1000 kgs
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px'
        >
          1 metric ton =1 ton jumbo bag packed in 45-48 kgs pp bag , hdpe bag (if required once used fine quality bag , jumbo bag (1 metric tonne ) and also loose packing
          <br />
          We are offering aggregate. Packing: 1 metric ton = 1000 kgs which can be packed in (21 to 22 bags) 1 metric ton =1 ton jumbo bag packed in 45-48 kgs pp bag , hdpe bag (if required once used fine quality bag , jumbo bag (1 metric tonne ) and also loose packing.
        </Text>
        <Image 
          src= {image2} 
          alt="60mm" 
          borderRadius='12px' 
          mb='20px' 
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          transition='transform 0.3s ease, box-shadow 0.3s ease'
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mt='25px' 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Aggregate 6mm to 60mm
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px'
        >
          We offer Blue Metal, Chips and blue metal dust in various grades, sizes and finishes that are helpful in construction industry. Our products are widely appreciated for their quality, efficiency and reliability. We obtain these products from our high-tech quarry. In the market, we use advanced techniques in making these Metals.
          <br /><br />
          Our company ensures all the consignments are delivered within a stipulated time frame. We ensure our products fulfill the requirement of customers. Our company has a huge warehouse that helps us to offer the products in a refined manner.
        </Text>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555'
        >
          <b>Available size: </b>
          From 6mm to 60mm
          <br />
          Blue metal chips,
          <br />
          Blue metal dust.
          <b>Product Details:</b>
          <br />
          Minimum Order Quantity – 10000 Ton
        </Text>
        <Image 
          src={image3}
          alt="Blue Granite Stone Chips" 
          borderRadius='12px' 
          mb='20px' 
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          transition='transform 0.3s ease, box-shadow 0.3s ease'
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Blue Granite Stone Chips
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px'
        >
          We offer Blue Granite Stone Chips to our valued clients. These types of stones are generally used in concrete and other road construction projects. The offered chips are very hard and do not blister. We offer these chips in standard blue color. Furthermore, to meet divergent demands of the clients, we offer these chips in different sizes and textures.
        </Text>
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Some of the foremost attributes of these stones are mentioned below:
        </Heading>
        <List 
          styleType='disc' 
          ml='30px' 
          mb='25px'
        >
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Resistance against harsh temperature
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Non-slippery
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Hard
          </ListItem>
        </List>
        <Image 
          src={image5}
          alt="landscape aggregate" 
          borderRadius='12px' 
          mb='20px' 
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          transition='transform 0.3s ease, box-shadow 0.3s ease'
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Landscaping Aggregates
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px'
        >
          We are offering a vast collection of Landscaping Aggregates to our valued clients. These are types of crushed stones, prominently used for construction purposes and landscaping projects. The offered range has impressed a huge clients base owing features it possesses. Furthermore, we provide these stones in different sizes, textures, and shapes to satisfy divergent demands of the clients.
        </Text>
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Here are the prominent features of these stones:
        </Heading>
        <List 
          styleType='disc' 
          ml='30px' 
          mb='25px'
        >
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Hard
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Non-slippery
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Waterproof
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Resistance against harsh temperature
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Do not blister
          </ListItem>
        </List>
        <Image 
          src= {image4} 
          alt="Coarse Sand" 
          borderRadius='12px' 
          mb='20px' 
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          transition='transform 0.3s ease, box-shadow 0.3s ease'
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Coarse Aggregate
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555'
        >
          Enriched with years of experience in the industry, we are engaged in offering Coarse Aggregate.
        </Text>
      </Box>
    </Box>
  );
};

export default Aggregates;
