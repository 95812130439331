import { Box, Divider, Heading, Image } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import style from "./Listing.module.css";
import Loader from "../UserListings/Loader";
// import buildingimg from "./mgpng.png"
import { Link, useParams } from "react-router-dom";
import AvatarComponent from "../../components/AvtarComponent/AvatarComponent";
import DeleteBox from "../UserListings/DeleteBox2";

const AgentListing = () => {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [agentData, setAgentData] = useState();
  const [name , setName]  = useState("")

  const { id } = useParams()

  const myListedProperty = async () => {
    setLoading(true);
    // let id = "665071bea8a2aafd68c0ec11" || undefined;
    try {
      await axios
        .get(`${process.env.REACT_APP_URL}/property/agent-listing/${id}/`,)
        .then((e) => {
          console.log(e);
          setdata(e.data.listing);
          setAgentData(e.data.agent);
          setName(e.data.agent.name);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    myListedProperty();
  }, []);

  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"} margin={"20px 20px"} className="avtar-listing">
       <AvatarComponent className = "agentprofile" size="2xl" name = {name} url={agentData?.avatar} />
        <Box marginLeft={"20px"}>
          <h2 style={{textTransform:"capitalize"}} >{agentData?.name}</h2>
          <p>Company Agent at <span style={{color:"skyblue"}}> {agentData?.companyName}</span></p>
          <hr />
          <p></p>
        </Box>
      </Box>


      <Box position={"relative"} display={"flex"} height={"auto"} my={6}>
        {/* property Listing */}

        <Box
          flex={10}
          w={"90%"}
          borderRadius={10}
          padding={"10px 20px"}
          marginX={"auto"}
          backgroundColor={"rgba(255, 255, 255, 0)"}
        >
          <Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Heading
                size={"md"}
                textAlign={"left"}
                fontWeight={"500"}
                marginLeft={"40px"}
              >
                {" "}
                Agent Listed Property{" "}
              </Heading>
            </Box>

            <Divider w={"98%"} margin={"10px auto 0 auto"} />
            {loading ? (
              <Loader />
            ) : (
              <Box
                className={style.displaygrid}
                backgroundColor={"white"}
                bord
                padding={4}
                borderRadius={10}
              >
                {data &&
                  data?.map((e, i) => (
                    <DeleteBox
                      key={i}
                      myListedProperty={myListedProperty}
                      loading={loading}
                      e={e}
                    />

                  ))}
              </Box>
            )}
          </Box>
        </Box>
        {/* addvertise */}
        {/* <Box 
        // zIndex={"-4"} 
        // clipPath={"polygon(50% 0, 100% 0, 100% 59%, 100% 100%, 45% 100%, 0 100%, 0 0, 56% 100%, 0 100%, 100% 100%)"} 
        flex={6}
      >
        <Image
          position={"sticky"}
          top={"100px"}
          src= "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dmlsbGF8ZW58MHx8MHx8fDA%3D" alt="png" />
      </Box> */}
      </Box>
    </Box>
  )
}

export default AgentListing