import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

const Extraimg = ({ e, propertyid, deleteimagePermanently }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [propertykey, setPropertykey] = useState("");

    const handleSavedImages = () => {
        onOpen();
    };

    useEffect(() => {
        setPropertykey(e.KEY);
        console.log(e);
    }, [e]);

    const deleteStyle = {
        fontSize: '1rem',
        color: 'red',
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '50%',
        padding: '3px 5px',
        lineheight: '0px',
        transition: 'background-color 0.3s ease, color 0.3s ease'
    };

    const imageContainerStyle = {
        position: 'relative',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap'
    };

    const imageStyle = {
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        height: '140px',
        width: '180px'
    };

    const modalHeaderStyle = {
        fontWeight: 'bold',
        fontSize: '1.2rem'
    };

    const modalBodyStyle = {
        fontSize: '1rem'
    };

    const modalFooterStyle = {
        display: 'flex',
        justifyContent: 'space-between'
    };

    return (
        <Box key={e._id} style={imageContainerStyle} >
            <Text
                style={deleteStyle}
                onClick={() => handleSavedImages(e._id)}
                onMouseLeave={e => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.7)'}
            >
                &#10006;
            </Text>
            <img src={e?.URL} alt="images" style={imageStyle} />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader style={modalHeaderStyle}>Delete image</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={modalBodyStyle}>
                        Are you sure? Image will be deleted permanently
                    </ModalBody>

                    <ModalFooter style={modalFooterStyle}>
                        <Button
                            colorScheme={"red"}
                            variant={"outline"}
                            onClick={() => {
                                onClose();
                                deleteimagePermanently(propertyid, propertykey);
                            }}
                            _hover={{ backgroundColor: "rgb(202, 26, 26)", color: "white" }}
                        >
                            Delete Permanently
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Extraimg;
