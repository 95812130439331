import React from 'react';
import { Box, Heading, Text, List, ListItem, Image, useBreakpointValue } from '@chakra-ui/react';

const Sand = () => {
  // Responsive font sizes
  const headingSize = useBreakpointValue({ base: '2xl', md: '3xl' });
  const subHeadingSize = useBreakpointValue({ base: 'xl', md: '2xl' });
  const paragraphSize = useBreakpointValue({ base: 'md', md: 'lg' });

  return (
    <Box 
      p={{ base: '15px', md: '20px' }} 
      fontFamily='Roboto, sans-serif' 
      bg='#f4f7f6' 
      minHeight='100vh'
      color='#333'
    >
      <Box
        maxW='1200px'
        mx='auto'
        p='30px'
        borderRadius='12px'
        boxShadow='0 8px 16px rgba(0, 0, 0, 0.1)'
        bg='#ffffff'
        transition='transform 0.3s ease, box-shadow 0.3s ease'
        _hover={{
          transform: 'scale(1.02)',
          boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Heading 
          as='h1' 
          fontSize={headingSize} 
          mb='15px' 
          color='#34495e'
          textShadow='1px 1px 2px rgba(0, 0, 0, 0.1)'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Sand
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px' 
          textAlign='justify'
        >
          Crushed Sand is used in preparing concrete mix along with cement and aggregates. River sand is found in river streams and banks and is fine in quality unlike pit sand. This type of sand has rounded grains generally white-grey in colour. River sand is used for brick-work and plastering during construction.
        </Text>
        <Image 
          src='./sand.jpg' 
          alt='sand image' 
          borderRadius='12px' 
          mb='20px' 
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          transition='transform 0.3s ease, box-shadow 0.3s ease'
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mt='25px' 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Sand 1000kgs
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px'
        >
          1 metric ton =1 ton jumbo bag. Packed in 45-48 kgs pp bag, HDPE bag (if required once used fine quality bag, jumbo bag (1 metric tonne) and also loose packing
          <br />
          We offer sand. Packing:
          1 metric ton = 1000 kgs which can be packed in (21 to 22 bags)
          <br />
          1 metric ton =1 MT jumbo bag. Packed in 45-48 kgs pp bag, hdpe bag (if required once used fine quality bag, jumbo bag (1 metric tonne) and also loose packing
        </Text>
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Features:
        </Heading>
        <List 
          styleType='circle' 
          ml='30px' 
          mb='25px'
        >
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            High strength
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Fine quality
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Use for construction purpose
          </ListItem>
        </List>
        <Image 
          src='./msand.jpg' 
          alt='msand' 
          borderRadius='12px' 
          mb='20px' 
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          transition='transform 0.3s ease, box-shadow 0.3s ease'
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mt='25px' 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          M Sand (Manufacturing Sand)
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px'
        >
          Manufactured sand is an alternative for river sand. Due to fast growing construction industry, the demand for sand has increased tremendously, causing deficiency of suitable river sand in most part of the world. Due to the depletion of good quality river sand for the use of construction, the use of manufactured sand has been increased. Another reason for use of M-Sand is its availability and transportation cost. Since this sand can be crushed from hard granite rocks, it can be readily available at the nearby place, reducing the cost of transportation from far-off river sand bed.
        </Text>
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          Uniqueness Of our M Sand
        </Heading>
        <List 
          styleType='circle' 
          ml='30px' 
          mb='25px'
        >
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            We are the only crusher in Chennai to use VSI technology to produce M sand
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            We use proper aggregates to produce M Sand
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Our materials will have only refined dust of 10-15%
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Our Materials Will get passed in Zone 2 parameters
          </ListItem>
          <ListItem mb='12px' color='#333' _hover={{ color: '#3498db' }}>
            Prompt Professional services will done for all the transactions
          </ListItem>
        </List>
        <Image 
          src='./river_sand.jpg' 
          alt='river sand' 
          borderRadius='12px' 
          mb='20px' 
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          transition='transform 0.3s ease, box-shadow 0.3s ease'
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Heading 
          as='h2' 
          fontSize={subHeadingSize} 
          mb='15px' 
          color='#34495e'
          borderBottom='2px solid #3498db'
          pb='5px'
          transition='border-color 0.3s ease'
          _hover={{ borderColor: '#2980b9' }}
        >
          River Sand
        </Heading>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555' 
          mb='25px'
        >
          We have carved a niche amongst the most trusted names in this business, engaged in offering the comprehensive range of River Sand.
        </Text>
        <Text 
          fontSize={paragraphSize} 
          lineHeight='1.8' 
          color='#555'
        >
          <b>Product Details:</b>
          <br />
          Application: Construction
          <br />
          Specific Gravity: 2.67
          <br />
          Bulk Density: 14.5 kN/m3
          <br />
          pH: 8.66
        </Text>
      </Box>
    </Box>
  );
};

export default Sand;
