import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';
import './HomeLoanCalculator.css';

// Register the necessary components with Chart.js
ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

const HomeLoanCalculator = () => {
    const [totalAmount, setTotalAmount] = useState('');
    const [downPayment, setDownPayment] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [loanTerm, setLoanTerm] = useState('');
    const [propertyTax, setPropertyTax] = useState('');
    const [homeInsurance, setHomeInsurance] = useState('');
    const [hoaFees, setHoaFees] = useState('');
    const [pmi, setPmi] = useState('');
    const [monthlyPayment, setMonthlyPayment] = useState(null);
    const [lineChartData, setLineChartData] = useState(null);
    const [doughnutChartData, setDoughnutChartData] = useState(null);
    const [country, setCountry] = useState('');

    const area = localStorage.getItem('astcountry');

    useEffect(()=>{
        if(area === undefined){
            setCountry("Rs.")
        }else if(area === "USA" || area === "us" || area === "usa"){
            setCountry("$")
        }else{
            setCountry("₹")
        }
    })

 

    useEffect(() => {
        if (totalAmount && downPayment && interestRate && loanTerm) {
            calculateMonthlyPayment();
        }
    }, [totalAmount, downPayment, interestRate, loanTerm, propertyTax, homeInsurance, hoaFees, pmi]);

    const calculateMonthlyPayment = () => {
        const principal = parseFloat(totalAmount) - parseFloat(downPayment);
        const monthlyInterestRate = (parseFloat(interestRate) / 100) / 12;
        const numberOfPayments = parseFloat(loanTerm) * 12;
        const monthlyPropertyTax = parseFloat(propertyTax) / 12;
        const monthlyHomeInsurance = parseFloat(homeInsurance) / 12;
        const monthlyPmi = parseFloat(pmi) / 12;

        const monthlyPrincipalAndInterest =
            (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

        const totalMonthlyPayment =
            monthlyPrincipalAndInterest +
            monthlyPropertyTax +
            monthlyHomeInsurance +
            parseFloat(hoaFees) +
            monthlyPmi;

        setMonthlyPayment(totalMonthlyPayment.toFixed(2));
        generateLineChartData(principal, monthlyPrincipalAndInterest, numberOfPayments);
        generateDoughnutChartData(monthlyPrincipalAndInterest, monthlyPropertyTax, monthlyHomeInsurance, parseFloat(hoaFees), monthlyPmi);
    };

    const generateLineChartData = (principal, monthlyPrincipalAndInterest, numberOfPayments) => {
        let remainingPrincipal = principal;
        let labels = [];
        let data = [];
        for (let i = 1; i <= numberOfPayments; i++) {
            const interestPayment = remainingPrincipal * (parseFloat(interestRate) / 100 / 12);
            const principalPayment = monthlyPrincipalAndInterest - interestPayment;
            remainingPrincipal -= principalPayment;

            labels.push(`Month ${i}`);
            data.push(remainingPrincipal < 0 ? 0 : remainingPrincipal);
        }

        setLineChartData({
            labels,
            datasets: [
                {
                    label: 'Remaining Principal',
                    data,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                }
            ]
        });
    };

    const generateDoughnutChartData = (principalAndInterest, propertyTax, homeInsurance, hoaFees, pmi) => {
        setDoughnutChartData({
            labels: [
                'Principal & Interest',
                'Property Tax',
                'Home Insurance',
                'HOA Fees',
                'PMI'
            ],
            datasets: [
                {
                    data: [principalAndInterest, propertyTax, homeInsurance, hoaFees, pmi],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(153, 102, 255, 0.2)'
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(153, 102, 255, 1)'
                    ],
                    borderWidth: 1
                }
            ]
        });
    };

    return (
        <div className="container">
            <div className='chart'>
                <div className='left'>
                    <h1 className='.h1'>Home Loan Calculator</h1>
                    <div className="input-group">
                        <label>Total Amount: </label>
                        <input type="number" value={totalAmount} onChange={(e) => setTotalAmount(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label>Down Payment: </label>
                        <input type="number" value={downPayment} onChange={(e) => setDownPayment(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label>Interest Rate (%): </label>
                        <input type="number" value={interestRate} onChange={(e) => setInterestRate(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label>Loan Term (Years): </label>
                        <input type="number" value={loanTerm} onChange={(e) => setLoanTerm(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label>Property Tax (Annual): </label>
                        <input type="number" value={propertyTax} onChange={(e) => setPropertyTax(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label>Home Insurance (Annual): </label>
                        <input type="number" value={homeInsurance} onChange={(e) => setHomeInsurance(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label>Monthly HOA Fees: </label>
                        <input type="number" value={hoaFees} onChange={(e) => setHoaFees(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label>PMI (% of Loan): </label>
                        <input type="number" value={pmi} onChange={(e) => setPmi(e.target.value)} />
                    </div>
                    <button className='buttonchart' onClick={calculateMonthlyPayment}>Calculate</button>
                </div>
                <div className="chart-container2">
                    <h2>Payment Breakdown</h2>
                    {doughnutChartData && <Doughnut data={doughnutChartData} />}
                </div>
            </div>


            {monthlyPayment && (
                <div className="chart-container">
                    <h2>Monthly Payment: {country}{monthlyPayment}</h2>
                    {lineChartData && <Line data={lineChartData} />}
                </div>
            )}

        </div>
    );
};

export default HomeLoanCalculator;
